<template>
  <section class="content">
    <div class="table-responsivex">
      <table class="table table-hover" ref="tbllistujian">
        <thead>
          <tr>
            <th>TIPE</th>
            <th>MAPEL</th>
            <th>JUDUL</th>
            <th>TANGGAL MULAI</th>
            <th>TANGGAL SELESAI</th>
            <th>KODE UJIAN</th>
            <th>JENIS UJIAN</th>
            <th>PROGRAM</th>
            <th>STATUS</th>
            <th>TINDAKAN</th>
          </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
      </table>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6 form-group i_mapel">
                    <label class="control-label">Mapel</label>
                    <v-select :options="optMapel" v-model="form.mapel_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group i_jenis_tes">
                    <label class="control-label">Jenis Tes</label>
                    <v-select required :options="optJenisTes" v-model="form.jenis_tes_id" :reduce="opt => opt.id" @input="changeJenisTes(form.jenis_tes_id)"/>
                    <input type="hidden" class="form-control is_psikotes" v-model="form.is_psikotes" name="is_psikotes">
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Program</label>
                  <v-select :options="optUjian" v-model="form.program_id" :reduce="opt => opt.program_id" multiple />
                </div>
                <div class="col-md-6 form-group">
                  <div class="col-5">
                    <label for="ujian_type" class="control-label">Tipe Ujian</label>
                  </div>
                  <div class="form-check form-check-inline ml-2">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="ujian_type"
                        id="ujian_type"
                        v-model="form.ujian_type"
                        value="ujian"
                    />
                    <label class="form-check-label" for="ujian_type">Try Out</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="ujian_type"
                        id="ujian_type"
                        v-model="form.ujian_type"
                        value="latihan"
                    />
                    <label class="form-check-label" for="ujian_type"
                        >latihan</label>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Judul</label>
                  <input
                    id="ujian_title"
                    class="form-control"
                    v-model="form.ujian_title"
                    type="text"
                    required="required"
                    name="ujian_title"
                  />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Range waktu (Tanggal Mulai & Tanggal)</label>
                    <input type="text" class="form-control" ref="daterange" id="daterange" autocomplete="off" required>
                </div>
                <div class="col-md-6 form-group i_durasi">
                    <label class="control-label">Durasi (menit)</label>
                    <input type="number" 
                      class="form-control" 
                      autocomplete="off" 
                      placeholder="Contoh : 60" 
                      v-model="form.durasi">
                </div>
                <div class="col-md-6 form-group">
                  <div class="col-5">
                    <label for="jenis_ujian" class="control-label">Jenis Ujian</label>
                  </div>
                  <div class="form-check form-check-inline ml-2">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="jenis_ujian"
                        id="jenis_ujian"
                        v-model="form.jenis_ujian"
                        value="offline"
                    />
                    <label class="form-check-label" for="jenis_ujian">Offline</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="jenis_ujian"
                        id="jenis_ujian"
                        v-model="form.jenis_ujian"
                        value="online"
                    />
                    <label class="form-check-label" for="jenis_ujian"
                        >Online</label>
                  </div>
                </div>
                <div class="col-md-6 form-group i_pembahasan">
                  <div class="col-5">
                    <label for="tampilkan_pembahasan" class="control-label">Tampilkan Pembahasan</label>
                  </div>
                  <div class="form-check form-check-inline ml-2">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="tampilkan_pembahasan"
                        id="tampilkan_pembahasan"
                        v-model="form.tampilkan_pembahasan"
                        value="1"
                    />
                    <label class="form-check-label" for="tampilkan_pembahasan">Ya</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="tampilkan_pembahasan"
                        id="tampilkan_pembahasan"
                        v-model="form.tampilkan_pembahasan"
                        value="0"
                    />
                    <label class="form-check-label" for="tampilkan_pembahasan"
                        >Tidak</label>
                  </div>
                </div>
                <div class="col-md-6 form-group i_nilai">
                  <div class="col-5">
                    <label for="tampilkan_nilai" class="control-label">Tampilkan Nilai</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="tampilkan_nilai"
                        id="tampilkan_nilai"
                        v-model="form.tampilkan_nilai"
                        value="1"
                    />
                    <label class="form-check-label" for="tampilkan_nilai"
                        >Ya</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="tampilkan_nilai"
                        id="tampilkan_nilai"
                        v-model="form.tampilkan_nilai"
                        value="0"
                    />
                    <label class="form-check-label" for="tampilkan_nilai"
                        >Tidak</label>
                  </div>
                </div>
                <div class="col-md-6 form-group i_pdf">
                    <label class="control-label">File PDF</label>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input inputfile" id="inputFile" name="file" @change="uploadFoto(this)">
                        <label class="custom-file-label" for="inputFile" ref="imgname">Choose file</label>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from "moment";

export default {
  name: "",
  data() {
    return {
      errors: [],
      optMapel: [],
      optJenisTes: [
        // {label:'Tes Koran',id:33},
        // {label:'Tes EPPS',id:35},
        // {label:'Tes Kecerdasan',id:36},
        {label:'Tes Kecermatan',id:34}
      ],
      optUjian: [],
      method: "",
      roles: "",
      formTitle: "Tambah",
      form: {
        mapel_id: "",
        program_id: [],
        ujian_type: "",
        jenis_ujian: "",
        ujian_title: "",
        durasi: "",
        tanggal_mulai: moment().format("DD/MM/YYYY"),
        tanggal_selesai: moment().add(31, "days").format("DD/MM/YYYY"),
        tampilkan_pembahasan: "",
        tampilkan_nilai: "",
        is_psikotes : 0,
        jenis_tes_id : ""
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch('/akademik/data_guru/mapel')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMapel = js.data;
    });

    authFetch('/akademik/ujian/program')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optUjian = js.data;
    });
  },
  components: { 
    vSelect, 
  },
  methods: {
    uploadFoto : function (file) {
        const e = this.$refs;
        var file_data = $('#inputFile').prop('files');
        // console.log(file_data)
        var form_data = new FormData();
        var fileInput = $('#inputFile')[0];
        $.each(fileInput.files, function(k,file){
          form_data.append('file', file);
          $(e.imgname).html(file.name);
        });
        form_data.append('subdir', '/soal-ujian/');
        form_data.append('id', this.form.id ? this.form.id : 0);
        this.formUpload = form_data
        // console.log('data poto',this.formUpload)
        return false
    },
    doUpload: function() {
        console.log('p upload', this.formUpload)
        return authFetch('/akademik/ujian/upload_data', {
            method: 'POST',
            body: this.formUpload,
            headers: {
              'Content-Type': null,
            }
        })
    },
    changeJenisTes: function(id) {
      this.form.mapel_id = id
    },
    submitForm: function (ev) {
        const e = this.$refs;
        var allData = { group: this.form };
        console.log(allData)
        var urlSubmit = "/akademik/ujian";
        if (this.method == "PUT")
          urlSubmit = "/akademik/ujian/" + this.form.id;
  
        var file_data = $('#inputFile').prop('files');
        console.log('list files', file_data.length);
        if (file_data.length == 0) {
          authFetch(urlSubmit, {
            method: this.method,
            body: JSON.stringify(allData),
          })
            .then((res) => {
              if (res.status === 201) {
              } else if (res.status === 400) {
              }
              return res.json();
            })
            .then((js) => {
              this.errors = [];
              if (!js.success) {
              //   console.log(js.details);
    
              //   for (var key in js.details) {
              //     if (js.details.hasOwnProperty(key)) {
              //       this.errors.push(js.details[key]);
              //     }
              //   }
    			Swal.fire("Proses gagal!", js.msg, "error");
                  return;
                }
                this.table.api().ajax.reload();
                $(e.formDialog).modal("hide");
              });
        } else {
          this.doUpload().then((res) => {
              if (res.status === 201) {

              } else if (res.status === 400) {}

              return res.json();
              
          }).then(js => {
              this.errors = [];
              if (!js.status) {
                  console.log(js.details)

                  for (var key in js.details) {
                      if (js.details.hasOwnProperty(key)) {
                          this.errors.push(js.details[key])
                      }
                  }

                  return;
              } else { 
                  if (js.message[0]) {
                      this.form.img_code = js.message[0].code;
                  }
                  allData = { group: this.form };

                  authFetch(urlSubmit, {
                    method: this.method,
                    body: JSON.stringify(allData),
                  }).then((res) => {
                      if (res.status === 201) {
                      } else if (res.status === 400) {
                      }
                      return res.json();
                    })
                    .then((js) => {
                      this.errors = [];
                      if (!js.success) {
                      //   console.log(js.details);
            
                      //   for (var key in js.details) {
                      //     if (js.details.hasOwnProperty(key)) {
                      //       this.errors.push(js.details[key]);
                      //     }
                      //   }
                      Swal.fire("Proses gagal!", js.msg, "error");
                          return;
                        }
                        this.table.api().ajax.reload();
                        $(e.formDialog).modal("hide");
                    });
              }
          })
        }
        ev.preventDefault();
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }

      if (e.target.closest("button")) {
          if (e.target.dataset.action == "view") {
              this.$router.push("/detail-ujian/" + e.target.dataset.id);
          } else if (e.target.dataset.action == "import") {
              this.$router.push("/detail-ujian/import/" + e.target.dataset.id);
          } else if (e.target.dataset.action == "show") {
              window.open(window.$apiUrl+'/file/view/'+e.target.dataset.id, "_blank");
          } else if (e.target.dataset.action == "psikotes"){
            this.$router.push("/detail-ujian/psikotes/" + e.target.dataset.id);
          } else {

          }
      }
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment(),
        endDate: moment().add(31, "days"),
        locale: {
            format: 'DD/MM/YYYY'
        },
        }, function(d1, d2) {
            self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
            self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
        });

    this.table = createTable(e.tbllistujian, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/akademik/ujian",
      scrollX: true,
      columns: [
        { data: "ujian_type" },
        { data: "mapel" },
        { data: "ujian_title" },
        { data: "tanggal_mulai",
        render: function (data, type, row, meta) {
          return moment(data).format('DD/MM/YYYY')
        }, },
        { data: "tanggal_selesai",
        render: function (data, type, row, meta) {
          return moment(data).format('DD/MM/YYYY')
        }, },
        { data: "ujian_code" },
        { data: "jenis_ujian",
        render: function (data, type, row, meta) {
            if(!data) return '-'; 
            else
            return `<h5><span class="badge badge-primary">${data}</span></h5>`;
        }, },
        { data: "program",
        render: function (data, type, row, meta) {
            let act = ``;
            for(var x in data)
            {
              var program = data[x];
              act += `<h5 class="mr-2" style="display:inline-block;"><span class="badge badge-primary">${program}</span></h5>`;
            }
              return act;
        } },
        { data: "status",
        render: function (data, type, row, meta) {
            return data > 0 ? '<h5><span class="badge badge-success">Active</span></h5>' : '<h5><span class="badge badge-secondary">Not Active</span></h5>'
        }, },
        { data: "action",
          sortable: false,
        render: function (data, type, row, meta) {
          var actions = ``;
          if (row.is_psikotes == 1) {
            actions = `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-warning" data-action="psikotes" data-id="`+row.id+`"><i class="fas fa-eye"></i> Preview</button></div>`;
          } else {
            actions = `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="`+row.id+`"><i class="fas fa-eye"></i> Detail</button></div>`;
          }

          if (row.img_code) {
            actions += `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-warning" data-action="show" data-id="`+row.img_code+`"><i class="fas fa-eye"></i> Preview</button></div>`;
          }

            return actions;
        }, },
      ],
      filterBy: [2, 5],
      rowCallback: function (row, data) {},
      buttonClick: (evt) => {
        const e = this.$refs;
        if (evt.role == "create") {
          console.log(evt.role)
        } else if (evt.role == "update" && evt.data) {
          //get program
          $(e.imgname).html('Choose file');
          let data = evt.data.program; //nama program
          let id_program = evt.data.program_id; //id program
          let len = data.length;
          let len2 = id_program.length;

          if (evt.data.program == []) {
            self.form.program_id = "";
          } else {
            self.form.program_id = [];
            if (len < 1 || len2 < 1) {
              self.form.program_id;
            } else {
              for(let i = 0; i < len && i < len2; i++)
              {
                self.form.program_id.push({
                      label: data[i],
                      program_id: id_program[i],
                });
              }
            }
          }

          self.form.id = evt.data.id;
          self.form.mapel_id = evt.data.mapel_id;
          self.form.ujian_type = evt.data.ujian_type;
          self.form.durasi = evt.data.durasi;
          self.form.jenis_ujian = evt.data.jenis_ujian;
          self.form.ujian_title = evt.data.ujian_title;
          self.form.tampilkan_pembahasan = evt.data.tampilkan_pembahasan;
          self.form.tampilkan_nilai = evt.data.tampilkan_nilai;
          self.form.is_psikotes = evt.data.is_psikotes;
          self.form.jenis_tes_id = evt.data.jenis_tes_id;
          self.form.tanggal_mulai = evt.data.tanggal_mulai;
          self.form.tanggal_selesai = evt.data.tanggal_selesai;

          new Daterangepicker(this.$refs.daterange, {
            startDate: moment(evt.data.tanggal_mulai),
            endDate: moment(evt.data.tanggal_selesai),
            locale: {
              format: 'DD/MM/YYYY'
            },
            }, function(d1, d2) {
                self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
                self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
          });

          //   self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Soal Ujian";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/akademik/ujian/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                  }
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
    var template = `
      <div class="btn btn-tool">
        <button class="btn btn-tool px-0" type="button" data-action="create" title="create" data-toggle="dropdown"><i class="fas fa-plus"></i></button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="button" data-action="create-akademik"><i class="fas fa-edit"></i> Tambah Soal Akademik</button>
          <button class="dropdown-item" type="button" data-action="create-psikotes"><i class="fas fa-edit"></i> Tambah Soal Psikotes</button>
        </div>  
      </div>
    `
    $('.btn-tool[data-action="create"]').replaceWith(template)
    $('.dropdown-item').click(function(){
      var tipe = $(this).attr('data-action');
      if(tipe == 'create-akademik'){
        $('.i_mapel').show();
        $('.i_jenis_tes').hide();
        $('.i_pdf').show();
        $('.i_pembahasan').show();
        $('.i_durasi').show();
        $('.i_nilai').show();
        self.formTitle = "Tambah Soal Akademik";
      } else {
        $('.i_mapel').hide();
        $('.i_jenis_tes').show();
        $('.i_pdf').hide();
        $('.i_pembahasan').hide();
        $('.i_durasi').hide();
        $('.i_nilai').hide();
        self.formTitle = "Tambah Soal Psikotes";
      }
      self.form = {
        jenis_tes_id : "",
        mapel_id: "",
        program_id: [],
        ujian_type: "",
        jenis_ujian: "",
        ujian_title: "",
        tanggal_mulai: moment(),
        tanggal_selesai: moment().add(31, "days"),
        tampilkan_pembahasan: "",
        tampilkan_nilai: "",
        is_psikotes : tipe == 'create-akademik' ? 0 : 1
      };
      self.method = "POST";
      self.errors = [];
      $(e.imgname).html('Choose file');
      $(e.formDialog).modal("show");
    })
  },
};
</script>
<style type="text/css">
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>